import EventBus, { SHOW_FILE } from "@/event-bus.js";

export default {
  install: function (Vue) {
    Vue.mixin({
      methods: {
        $openFile (file) {
          EventBus.$emit(SHOW_FILE, file);
        },
      },
    })
  }
}
