<template>
  <b-modal ref="modal" 
    centered 
    hide-footer 
    @shown="startCounter" 
    
    header-class="bg-primary text-white"
    no-close-on-backdrop
    hide-header-close
    no-close-on-esc>
    <template #modal-title>
      Neue App-Version
    </template>
    <div class="d-block text-center">
      <div>Es wurde eine neue App-Version veröffentlicht.</div>
      <div>Dieses Fenster aktualisiert sich automatisch in</div>
      <div class="font-weight-bold font-size-xxl">{{ counter }}</div>
      <div>Sekunden</div>
    </div>
    <div class="justify-content-center d-flex mt-3">
      <b-button variant="primary" class="mr-1" @click="reload">Jetzt aktualisieren</b-button>
      <b-button variant="danger" class="ml-1" @click="cancel">Abbrechen</b-button>
    </div>
  </b-modal>
</template>

<script>

import modal from "@m/modal";

const secondsToRefresh = 30

export default {
  name: "NewVersionModal",
  mixins: [ modal ],
  data() {
    return {
      counter: null,
      interval: null
    }
  },
  methods: {
    startCounter() {
      var self = this;
      self.counter = secondsToRefresh
      self.interval = setInterval(() => {
        if(self.counter === 0) {
          self.reload();
        } else {
          self.counter --
        }
        
      }, 1000)
    },
    cancel() {
      clearInterval(this.interval)
      this.hide();
    },
    reload() {
      location.reload();
    }
  }
}
</script>

<style>

</style>