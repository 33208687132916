export function mitarbeitername(value) {
  return value ? `${value.vorname} ${value.name}` : ""
}


export function setup (instance) {
  instance.filter("full_name", value => {
    return value?.fullName || ""
  })

  instance.filter("mitarbeitername", mitarbeitername)

  instance.filter("initials", value  => {
    if(value.name && value.vorname) {
      return `${value.vorname[0]}${value.name[0]}`.toUpperCase();
    } else {
      return ""
    }
  })
}
