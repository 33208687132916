import _ from "lodash";

import privateLayout from "@l/Private.vue"
import userRoutes from "./user";
import projektmappeRoutes from "./projektmappe";
import settingsRoutes from "./settings";

const routes = [
  {
    name: "session-expired",
    path: "/session-expired",
    component: () => import("@v/Login.vue"),
    props: {
      sessionExpired: true
    }
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@v/Login.vue")
  },
  {
    path: "/p",
    component: privateLayout,
    name: 'private',
    children: _.flatten([
      [
        {
          name: "startseite",
          path: "start",
          component: () => import("@v/Startseite.vue")
        },
        {
          name: "forbidden",
          path: "forbidden",
          component: () => import("@v/errors/Forbidden.vue")
        },
        {
          name: "filesystem-test",
          path: "filesystem",
          component: () => import("@v/FileSystemTest.vue")
        }
      ],
      userRoutes,
      projektmappeRoutes,
      settingsRoutes
    ])
  },
  {
    path: "*",
    component: () => import("@v/errors/NotFound.vue")
  }
]

export default routes
