<template>
  <vue-date-time-picker v-model="date" :id="id" format="DD.MM.YYYY" formatted="DD.MM.YYYY" :label="label" only-date
    auto-close no-clear-button button-now-translation="Heute" earlier-translation="Früher" later-translation="Später"
    :input-size="size" :error="error" :hint="hint" :position="position" :disabled="disabled"
    :transitioning-label="transitioningLabel" no-label :class="classes" />
</template>

<script>
import { v4 as uuid } from "uuid";

export default {
  name: "Datepicker",
  props: {
    label: {
      type: String,
      default: "Datum auswählen",
    },
    value: {
      type: [String, Object],
    },
    error: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
    position: {
      type: String,
      default: null,
    },
    transitioningLabel: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      date: this.value,
    };
  },
  watch: {
    value(val) {
      this.date = val;
    },
    date(val) {
      if (val !== this.value) this.$emit("input", val);
    },
  },
  computed: {
    id() {
      return uuid();
    },
  },
};
</script>

<style lang="scss"></style>
