import _ from "lodash";
import numeral from "numeral";

export function price(value) {
  if (isNaN(value) || !isFinite(value)) return "";
  return numeral(value).format("0,0.00");
}

export function priceEuro(value) {
  if (isNaN(value) || !isFinite(value)) return "";
  return `${numeral(value).format("0,0.00")} €`;
}

export function priceEuroOrBlank(value) {
  if (isNaN(value) || !isFinite(value) || !value) return "";
  return priceEuro(value);
}

export function padStart(value, length) {
  if (isNaN(value) || !isFinite(value)) return "";
  return _.padStart(value, length, '0')
}

export function thousendSeparator(value) {
  if (isNaN(value) || !isFinite(value) || !value) return "";
  return numeral(value).format("0,0");
}

export function setup(instance) {
  instance.filter("thousendSeparator", (value) => {
    return thousendSeparator(value)
  });

  instance.filter("price", (value) => {
    return price(value)
  });

  instance.filter("priceEuro", (value) => {
    return priceEuro(value)
  });

  instance.filter("percent", (value) => {
    if (isNaN(value) || !isFinite(value)) return "";
    return numeral(value).format("0.[00] %");
  });

  instance.filter("onePrecision", (value) => {
    if (isNaN(value) || !isFinite(value)) return "";
    return numeral(value).format("0,0.0");
  });

  instance.filter("padStart", (value, length) => {
    return padStart(value, length)
  });
}

