import api from "@a/config";

const path = "v1/projektmappe/anfrage"

export default {
  get: (
    page,
    size,
    sort = null,
    desc = false,
    jahr = null,
    suche = null,
    art = null,
    ergebnis = null,
    status = null,
    bauherr = null,
    vergabenummer = null,
    bauvorhaben = null,
    bearbeiter = null,
    oeffentlich = null,
    ohneBearbeiter = null,
    angebotsabgabe = null,
    nichtAbgeschlossen = null
  ) => {
    return api.get(path, { params: { page, size, sort, desc, jahr, suche, art, ergebnis, status, bauherr, vergabenummer, bauvorhaben, bearbeiter, oeffentlich, ohneBearbeiter, angebotsabgabe, nichtAbgeschlossen } })
  },
  getById: (id) => {
    return api.get(`${path}/${id}`)
  },
  getNaechsteNummer: (jahr) => {
    return api.get(`${path}/naechste_nummer`, { params: { jahr } })
  },
  getNaechsteLaufendeNummer: (jahr, ohne_lfd_nr) => {
    return api.get(`${path}/naechste_lfd_nummer`, { params: { jahr, ohne_lfd_nr } })
  },
  create: (anfrage) => {
    return api.post(path, anfrage)
  },
  update: (id, updateAnfrage) => {
    return api.put(`${path}/${id}`, updateAnfrage)
  },
  delete: (id) => {
    return api.delete(`${path}/${id}`)
  },
  import: (formData) => {
    return api.post(`${path}/import`, formData)
  },
  getZuordnung: () => {
    return api.get(`${path}/zuordnung`)
  },
  getAngebote: (id) => {
    return api.get(`${path}/${id}/angebot`)
  },
  createAngebot: (id) => {
    return api.post(`${path}/${id}/angebot`)
  },
  createDeckblatt: (id) => {
    return api.post(`${path}/${id}/deckblatt`)
  }
}
