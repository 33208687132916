import anfrage from "./anfrage";
import angebot from "./angebot";
import auftrag from "./auftrag";


export default {
  anfrage,
  angebot,
  auftrag
}
