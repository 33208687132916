const de = {
  'month-long': {
    month: 'long'
  },
  'date': {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }
}

export default de
