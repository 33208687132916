import formatter from "@u/formatter"

import { dateFromMillis } from "@/filter/date"
import { mitarbeitername } from "@/filter/mitarbeiter"
import { price } from "@/filter/numeral"
import { padStart } from './../../../filter/numeral';

export var laufendeNummer = {
  key: "nummer",
  sortable: true,
  sortKey: "angebot.nummer",
  label: "Nr.",
  thClass: "text-center",
  tdClass: "text-center",
  formatter: val => padStart(val, 3)
}

export var angebotsnummer = {
  key: "angebotsnummer",
  sortable: true,
  sortKey: "angebot.angebotsnummer",
  label: "Nr.",
  thClass: "text-center",
  tdClass: "text-center"
}

export var datum = {
  key: "datum",
  sortable: true,
  sortKey: "angebot.datum",
  label: "Datum",
  tdClass: "text-center",
  thClass: "text-center",
  formatter: dateFromMillis
}

export var anschrift = {
  key: "anschrift",
  sortable: true,
  sortKey: "angebot.anschrift",
  label: "Anschrift",
}

export var objekt = {
  key: "objekt",
  sortable: true,
  sortKey: "angebot.objekt",
  label: "Objekt",
}

export var geschaeftsfeld = {
  key: "geschaeftsfeld",
  sortable: true,
  sortKey: "angebot_geschaeftsfeld.sort",
  label: "Geschäftsfeld",
  formatter: val => {
    return val ? `${val.sort} - ${val.name}` : ""
  }
}

export var anfragenummer = {
  key: "anfragenummer",
  sortable: true,
  sortKey: "angebot.anfragenummer",
  label: "Anfragenummer",
  tdClass: "text-center",
  thClass: "text-center",
}
export var art = {
  key: "art",
  sortable: true,
  sortKey: "angebot_art.name",
  label: "Art",
  formatter: formatter.standardKategorie
}

export var anfrage_art = {
  key: "anfrage.art",
  sortable: true,
  sortKey: "anfrage_art.name",
  label: "Art",
  formatter: formatter.standardKategorie
}

export const status = {
  key: "status",
  sortable: true,
  sortKey: "angebot_status.sort",
  label: "Status",
  formatter: formatter.standardKategorie
}

export var bearbeiter = {
  key: "bearbeiter",
  sortable: true,
  sortKey: "bearbeiter.name",
  label: "Bearbeiter",
  tdClass: "text-center",
  thClass: "text-center",
  formatter: (value) => {
    return value ? mitarbeitername(value) : ""
  }
}
export var gegenpruefer = {
  key: "gegenpruefer",
  label: "Gegenprüfer",
  tdClass: "line-breaks",
  formatter: (value) => {
    return value.map(e => e.full_name).join('\n')
  }
}

export var summe_brutto = {
  key: "summe_brutto",
  sortable: true,
  sortKey: "angebot.summe_brutto",
  label: "Summe Brutto",
  tdClass: "text-right",
  thClass: "text-center",
  formatter: price
}

export var angebotssumme_erster_platz = {
  key: "angebotssumme_erster_platz",
  sortable: true,
  sortKey: "angebot.angebotssumme_erster_platz",
  label: "Angebotssumme Submissi. sieger Brutto",
  tdClass: "text-right",
  thClass: "text-center",
  formatter: price
}

export var angebotssumme_zweiter_platz = {
  key: "angebotssumme_zweiter_platz",
  sortable: true,
  sortKey: "angebot.angebotssumme_zweiter_platz",
  label: "Angebotssumme 2. Platz Brutto",
  tdClass: "text-right",
  thClass: "text-center",
  formatter: price
}

export var angebotssumme_dritter_platz = {
  key: "angebotssumme_dritter_platz",
  sortable: true,
  sortKey: "angebot.angebotssumme_dritter_platz",
  label: "Angebotssumme 3. Platz Brutto",
  tdClass: "text-right",
  thClass: "text-center",
  formatter: price
}

export var anzahl_bieter = {
  key: "anzahl_bieter",
  sortable: true,
  sortKey: "angebot.anzahl_bieter",
  label: "Anzahl Bieter",
  tdClass: "text-center",
  thClass: "text-center",
  formatter: val => val || ""
}

export var platzierung = {
  key: "platzierung",
  sortable: true,
  sortKey: "angebot.platzierung",
  label: "Platzierung MMS",
  tdClass: "text-center",
  thClass: "text-center",
  formatter: val => val || ""
}

export var submissionsergebnis = {
  key: "submissionsergebnis",
  sortable: true,
  sortKey: "anfrage_ergebnis.submissionsergebnis",
  label: "Submissionserg.",
  tdClass: "text-center text-nowrap",
  thClass: "text-center",

}

export default [
  laufendeNummer,
  datum,
  anschrift,
  objekt,
  geschaeftsfeld,
  anfragenummer,
  //art,
  anfrage_art,
  status,
  bearbeiter,
  gegenpruefer,
  summe_brutto,
  angebotssumme_erster_platz,
  angebotssumme_zweiter_platz,
  angebotssumme_dritter_platz,
  anzahl_bieter,
  platzierung,
  submissionsergebnis,

];