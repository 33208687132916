import BatixIndustrialUIFramework from "@bx/batix-industrial-ui-framework"
import Loadingoverlay from "./loadingoverlay"
import Loading from 'vue-loading-overlay';
import Numberformatter from './numberformatter';
import environment from './environment';
import VueMeta from 'vue-meta';
import Notificationhelper from "./notificationhelper";
import Permission from "./permission";
import Vuelidate from 'vuelidate'
import VueI18n from 'vue-i18n'
import { abilitiesPlugin } from '@casl/vue'
import { Ability } from '@casl/ability'
import moduleabilities from './moduleabilities'
import files from './files'
import user from './user'
import { isMobile, isAndroid, isWinPhone, isIOS } from 'mobile-device-detect';
//import VueQuill from 'vue-quill-editor'
import VueShortkey from 'vue-shortkey'
import SignaturePad from "vue-signature-pad";

/**
 * Setup Plugins for the Vue instance
 * @param {VueConstructor<Vue>} instance
 */
export function setupPlugins (instance) {

  instance.use(BatixIndustrialUIFramework)

  instance.use(VueMeta, { refreshOnceOnNavigation: true });

  instance.use(Loading);

  instance.use(Loadingoverlay);

  instance.use(Numberformatter);

  instance.use(environment, { isMobile: isMobile || isAndroid || isWinPhone || isIOS });

  instance.use(Notificationhelper);

  instance.use(Vuelidate);

  instance.use(Permission);

  instance.use(VueI18n);

  instance.use(abilitiesPlugin, new Ability([]));

  instance.use(moduleabilities);

  instance.use(files);

  instance.use(user);

  //instance.use(VueQuill);

  instance.use(SignaturePad);
  
  instance.use(VueShortkey);

}
