
function createKey(entityName, id) {
  return `form_${entityName}_${id || 'new'}`
}


export default {
  set: function(entityName, id, value) {
    localStorage.setItem(createKey(entityName, id), JSON.stringify(value))
  },
  exists: function(entityName, id) {
    let e = localStorage.getItem(createKey(entityName, id))
    return e !== null
  },
  get: function (entityName, id) { 
    let e = localStorage.getItem(createKey(entityName, id))
    if(e) return JSON.parse(e) 
    else return null
  },
  clear: function (entityName, id) { 
    localStorage.removeItem(createKey(entityName, id))
  }
}