import formatter from "@u/formatter"

import { dateFromMillis, dateTimeFromMillis } from "@/filter/date"
import { price } from "@/filter/numeral"
import { padStart } from './../../../filter/numeral';

export var laufendeNummer = {
  key: "laufende_nummer",
  sortable: true,
  sortKey: "anfrage.laufende_nummer",
  label: "Lfd. Nr.",
  thClass: "text-nowrap text-center",
  tdClass: "text-center",
  formatter: val => padStart(val, 3)
}

export var nummer = {
  key: "nummer",
  sortable: true,
  sortKey: "anfrage.nummer",
  label: "Anfrage. Nr",
  thClass: "text-nowrap text-center",
  tdClass: "text-nowrap text-center",
  formatter: val => padStart(val, 3)
}

export var datum = {
  key: "datum",
  sortable: true,
  sortKey: "anfrage.datum",
  label: "Datum",
  tdClass: "text-center",
  thClass: "text-center",
  formatter: dateFromMillis
}
export var absender = {
  key: "absender",
  sortable: true,
  sortKey: "anfrage.absender",
  label: "Absender",
}
export var anfragenummer = {
  key: "anfragenummer",
  sortable: true,
  sortKey: "anfrage.anfragenummer",
  label: "Anfrage Nr.",
  tdClass: "text-center",
  thClass: "text-center",
  stickyColumn: true
}
export var angebotsnummer = {
  key: "angebotsnummer",
  sortable: true,
  sortKey: "anfrage.angebotsnummer",
  label: "Angebotsnummer",
  tdClass: "text-center",
  thClass: "text-center",
}

export var geschaetzteAngebotssumme = {
  key: "geschaetzte_angebotssumme_number",
  sortable: true,
  sortKey: "anfrage.geschaetzte_angebotssumme_number",
  label: "Geschätzte Angebotssumme",
  tdClass: "text-right",
  thClass: "text-right",
  formatter: price
}
export var art = {
  key: "art",
  sortable: true,
  sortKey: "anfrage_art.name",
  label: "Art",
  formatter: formatter.standardKategorie
}
export var ausfuehrung = {
  key: "ausfuehrung",
  sortable: true,
  sortKey: "anfrage.ausfuehrung",
  label: "Ausführung",
  tdClass: "text-center",
  thClass: "text-center",
}
export var bauherr = {
  key: "bauherr",
  sortable: true,
  sortKey: "anfrage.bauherr",
  label: "Bauherr",
  tdClass: "line-breaks"
}
export var bauvorhaben = {
  key: "bauvorhaben",
  sortable: true,
  sortKey: "anfrage.bauvorhaben",
  label: "Bauvorhaben",
  tdClass: "line-breaks"
}
export var bearbeiter = {
  key: "bearbeiter",
  sortable: true,
  sortKey: "bearbeiter.vorname",
  label: "Bearbeiter",
  formatter: formatter.mitarbeiterName
}

export var bemerkung = {
  key: "bemerkung",
  sortable: true,
  sortKey: "anfrage.bemerkung",
  label: "Bemerkung",
}
export var datumPosteingang = {
  key: "datum_posteingang",
  sortable: true,
  sortKey: "anfrage.datum_posteingang",
  label: "Posteing.",
  tdClass: "text-center",
  thClass: "text-center",
  formatter: dateFromMillis
}
export var ergebnis = {
  key: "ergebnis",
  sortable: true,
  sortKey: "anfrage_ergebnis.name",
  label: "Ergebnis",
  tdClass: "text-center",
  thClass: "text-center",
  formatter: formatter.standardKategorie
}
export var gewerk = {
  key: "gewerk",
  sortable: true,
  sortKey: "anfrage.gewerk",
  label: "Gewerk",
}
export var kosten = {
  key: "kosten",
  sortable: true,
  sortKey: "anfrage.kosten",
  label: "Kosten",
  tdClass: "text-right",
  thClass: "text-right",
  formatter: price
}
export var submission = {
  key: "submission",
  sortable: true,
  sortKey: "anfrage.submission",
  label: "Submission",
  tdClass: "text-center text-nowrap",
  thClass: "text-center",  
  formatter: dateTimeFromMillis,
  
}
export var vergabenummer = {
  key: "vergabenummer",
  sortable: true,
  sortKey: "anfrage.vergabenummer",
  label: "Vergabenummer",
  tdClass: "text-center text-nowrap",
  thClass: "text-center",
}

export var angebotsabgabe = {
  key: "angebotsabgabe",
  sortable: true,
  sortKey: "anfrage.angebotsabgabe",
  label: "Angebotsabgabe",
  tdClass: "text-center text-nowrap",
  thClass: "",
}

export var status = {
  key: "status",
  sortable: true,
  sortKey: "anfrage_status.name",
  label: "Status",
  formatter: formatter.standardKategorie
}

export default [
  laufendeNummer,
  datum,
  absender, 
  nummer,
  anfragenummer, 
  angebotsnummer, 
  geschaetzteAngebotssumme,
  art, 
  ausfuehrung, 
  bauherr, 
  bauvorhaben, 
  bearbeiter, 
  bemerkung, 
  datumPosteingang, 
  ergebnis, 
  gewerk, 
  kosten, 
  submission, 
  vergabenummer,
  angebotsabgabe,
  status
];