import config from "@ls/config";
import { CACHE_VERSION, STAMMDATEN } from './key.type';


function getCacheVersion () {
  let val = localStorage.getItem(config.cachePrefix + CACHE_VERSION)
  if (val) return parseInt(val)
  return null
}

function getStammdaten() { 
  let stammdaten = localStorage.getItem(STAMMDATEN);
    if(stammdaten !== null) return JSON.parse(stammdaten)
    else return null
}

export default {
  setCacheVersion: (value) => {
    localStorage.setItem(config.cachePrefix + CACHE_VERSION, value)
  },
  getCacheVersion: getCacheVersion,
  isReloadRequired: (cache_key) => {
    let localCacheKey = getCacheVersion()
    return localCacheKey === null || localCacheKey !== cache_key || getStammdaten() === null
  },
  getStammdaten: getStammdaten,
  setStammdaten: (value) => {
    localStorage.setItem(STAMMDATEN, JSON.stringify(value))
  }
}