import { SET_AKTIVITAETARTEN } from "./mutations.type";

export default {
  namespaced: true,
  state: {
    aktivitaet_arten: null,
  },
  mutations: {
    [SET_AKTIVITAETARTEN](state, val) {
      state.aktivitaet_arten = val
    }
  },
  actions: {
  
  },
  modules: {
  },
  getters: {

  }
}
