import api from "@a/config";

const path = "v1/hr/mitarbeiter"

export default {
  get: (page, size, sort = null, desc = false, suche = null, aktuelle = null, ausgetretene = null, stichtag = null, abteilungen = null) => {
    return api.get(path, { params: { page, size, sort, desc, suche, aktuelle, ausgetretene, stichtag, abteilungen: abteilungen?.join(",") } })
  },
  getById: (id) => {
    return api.get(`${path}/${id}`)
  },
  create: (mitarbeiter) => {
    return api.post(path, mitarbeiter)
  },
  update: (id, mitarbeiter) => {
    return api.put(`${path}/${id}`, mitarbeiter)
  },
  delete: (id) => {
    return api.delete(`${path}/${id}`)
  },
  checkPersonalnummerAvailable: (personalnummer, id) => {
    return api.get(`${path}/personalnummer/available`, { params: { personalnummer, id } })
  }
}
