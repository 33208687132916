import Navbar from "./Navbar"
import Datepicker from "../components/Datepicker";
import VueSelect from "vue-select";
import { Can } from '@casl/vue';

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import VueDateTimePicker from '@bx/vue-date-time-picker';
import QuillEditor from '@c/quill/QuillEditor.vue';


/**
 * Setup Global accessible Components for the Vue instance
 * @param {VueConstructor<Vue>} instance
 */
export function setupGlobalComponents (instance) {

  instance.component('navbar', Navbar);

  instance.component('vue-date-time-picker', VueDateTimePicker);

  instance.component('datepicker', Datepicker);

  instance.component('v-select', VueSelect);

  instance.component('Can', Can);
  
  instance.component('quill-editor', QuillEditor);
}
