export default {
  newAppVersion: "new_app_version",
  connectionOpened: "connection_opened",
  registration: "registration",
  joinRoom: "join",
  leaveRoom: "leave",
  generic: "message",
  entityUpdate: "entity_update"
}

const operations = {
  
}

export { operations }