<template>
  <div  class="position-fixed" :class="{ 'invisible': !active }" style="top: 0px; left: 0px">
    <div
      class="position-fixed d-none d-sm-block d-md-none"
      style="z-index: 20000; height: 1px"
      v-b-visible="handleSmVisible"
    ></div>
    <div
      class="position-fixed d-none d-md-block d-lg-none"
      style="z-index: 20000; height: 1px"
      v-b-visible="handleMdVisible"
    ></div>
    <div
      class="position-fixed d-none d-lg-block d-xl-none"
      style="z-index: 20000; height: 1px"
      v-b-visible="handleLgVisible"
    ></div>
    <div
      class="position-fixed d-none d-xl-block d-xxl-none"
      style="z-index: 20000; height: 1px"
      v-b-visible="handleXlVisible"
    ></div>
    <div
      class="position-fixed d-none d-xxl-block d-uw-none"
      style="z-index: 20000; height: 1px"
      v-b-visible="handleXxlVisible"
    ></div>
    <div
      class="position-fixed d-none d-uw-block"
      style="z-index: 20000; height: 1px"
      v-b-visible="handleUwVisible"
    ></div>
  </div>
</template>

<script>
  //const breakpoints = [ "sm", "md", "lg", "xl", "xxl", "uw" ]

  import { VIEWPORT } from "@st/modules.type"
  import { SET_IS_LG, SET_IS_MD, SET_IS_SM, SET_IS_UW, SET_IS_XL, SET_IS_XXL } from "@st/viewport/mutations.type"

  export default {
    name: "ViewportControl",
    data() {
      return {
        active: false
      }
    },
    methods: {
      handleSmVisible(visible) {
        this.$store.commit(`${VIEWPORT}/${SET_IS_SM}`, visible)
      },
      handleMdVisible(visible) {
        this.$store.commit(`${VIEWPORT}/${SET_IS_MD}`, visible)
      },
      handleLgVisible(visible) {
        this.$store.commit(`${VIEWPORT}/${SET_IS_LG}`, visible)
      },
      handleXlVisible(visible) {
        this.$store.commit(`${VIEWPORT}/${SET_IS_XL}`, visible)
      },
      handleXxlVisible(visible) {
        this.$store.commit(`${VIEWPORT}/${SET_IS_XXL}`, visible)
      },
      handleUwVisible(visible) {
        this.$store.commit(`${VIEWPORT}/${SET_IS_UW}`, visible)
      },
    },
    mounted() {
      var self = this;
      this.$nextTick(() => {
        self.active = true;
      })
    }
  };
</script>

<style>
</style>