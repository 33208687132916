import api from "@a/config";

const path ="v1/hr/abteilung"

export default {
  get: () => {
    return api.get(path)
  },
  getById: (id) => {
    return api.get(`${path}/${id}`)
  },
  create: (abteilung) => {
    return api.post(path, abteilung)
  },
  update: (id, abteilung) => {
    return api.put(`${path}/${id}`, abteilung)
  },
  delete: (id) => {
    return api.delete(`${path}/${id}`)
  }
}
