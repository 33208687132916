export default {
  data () {
    return {
      state: false
    }
  },
  methods: {
    show () {
      this.$refs.modal.show();
    },
    hide () {
      this.$refs.modal.hide();
    }
  }
}
