import api from "@a/config";

const path = "v1/aktivitaet"

export default {
  update: (id, formData) => {
    return api.put(`${path}/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },
  delete: (id) => {
    return api.delete(`${path}/${id}`)
  }
}
