import store from "@st/index";

export default {
  install: function (Vue) {
    Vue.mixin({
      computed: {
        $currentUser() {
          return store.state.session.user;
        },
        $currentMitarbeiter() {
          return store.state.session.mitarbeiter;
        },
        $currentUsername() {
          return store.getters["session/username"];
        },
        $currentUserId() {
          return this.$currentUser?.id;
        },
        $isSupervisor() {
          return this.$currentUser?.isBatixSupervisor
            || this.$currentUser?.isClientSupervior
            || this.$currentUser?.isSupervisor
            || this.$currentUser?.isSystemSupervisor
        }
      },
    })
  }
}
