/* eslint-disable no-console */
import websocketmessage from "./message";
import EventBus, { ENTITY_UPDATE, SHOW_NEW_APP_VERSION } from "@/event-bus";
import store from "@st/index";
import { SESSION } from '@st/modules.type';
import { SET_WEBSOCKET_CLIENT_ID } from "@/store/session/mutations.type";


var websocket = null;

export default {
  connect: (userId, application) => {


    websocket = new WebSocket(getWebsocketUrl())

    websocket.onerror = () => {
      console.error("[Websocket] Error");
      websocket.send(JSON.stringify({ type: websocketmessage.registration, room: null, user: userId, application: application }));
    };

    websocket.onopen = () => {
      console.success("[Websocket] Successfully connected");
      websocket.send(JSON.stringify({ type: websocketmessage.registration, room: null, user: userId, application: application }));
    };

    websocket.onclose = () => {
      console.info("[Websocket] Disconnected");
    };

    websocket.onmessage = async function (event) {
      await handleMessage(event);
    };

    websocket.join = function (room) {
      if (websocket.readyState === websocket.OPEN) {
        websocket.send(JSON.stringify({ type: websocketmessage.joinRoom, room: room }))
        console.info(`[Websocket] Joined Room ${room}`)
      }
    };

    websocket.leave = function (room) {
      if (websocket.readyState === websocket.OPEN) {
        websocket.send(JSON.stringify({ type: websocketmessage.leaveRoom, room: room }))
        console.info(`[Websocket] Leaved Room ${room}`)
      }
    };

    return websocket
  },
}

function getWebsocketUrl() {
  let protocol = "wss:"
  let host = process.env.VUE_APP_SERVER_HOST;
  if (location.host.startsWith('localhost')) protocol = "ws:"
  if (!host) { host = location.host }
  let websocketUrl = `${protocol}//${host}/.well-known/bx-websockets/com.batix.cmsplugins:websocket-message-broker/websocket`
  return websocketUrl
}



var handleMessage = async function (event) {
  let message = JSON.parse(event.data);
  switch (message.operation) {
    case websocketmessage.newAppVersion:
      console.info(`${new Date()} Neue App Version hochgeladen`);
      EventBus.$emit(SHOW_NEW_APP_VERSION)
      break;
    case websocketmessage.connectionOpened:
      store.commit(`${SESSION}/${SET_WEBSOCKET_CLIENT_ID}`, message.payload)
      break;
    case websocketmessage.entityUpdate:
      if (store.state[SESSION].websocket_client_id !== message.payload?.websocketClientId) {
        EventBus.$emit(ENTITY_UPDATE, message.payload?.entity)
      }
      break;
  }
}
