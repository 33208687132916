
import {  } from "./actions.type";
import { SET_IS_LG, SET_IS_MD, SET_IS_SM, SET_IS_UW, SET_IS_XL, SET_IS_XXL } from "./mutations.type";

export default {
  namespaced: true,
  state: {
    isSm: false,
    isMd: false,
    isLg: false,
    isXl: false,
    isXxl: false,
    isUw: false
  },
  mutations: {
    [SET_IS_SM](state, val) {
      state.isSm = val
    },
    [SET_IS_MD](state, val) {
      state.isMd = val
    },
    [SET_IS_LG](state, val) {
      state.isLg = val
    },
    [SET_IS_XL](state, val) {
      state.isXl = val
    },
    [SET_IS_XXL](state, val) {
      state.isXxl = val
    },
    [SET_IS_UW](state, val) {
      state.isUw = val
    },
  },
  actions: {
    
  },
  modules: {

  },
  getters: {
    isSmOrWider: (state) => {
      return state.isSm || state.isMd || state.isLg || state.isXl || state.isXxl || state.isUw
    },
    isMdOrWider: (state) => {
      return state.isMd || state.isLg || state.isXl || state.isXxl || state.isUw
    },
    isLgOrWider: (state) => {
      return state.isLg || state.isXl || state.isXxl || state.isUw
    },
    isXlOrWider: (state) => {
      return state.isXl || state.isXxl || state.isUw
    },
    isXxlOrWider: (state) => {
      return state.isXxl || state.isUw
    },
    isUwOrWider: (state) => {
      return state.isUw
    }
  }
}
