<template>
  <div>
    <b-sidebar id="sidebar" title="Menü" bg-variant="white" shadow backdrop>
      <div class="px-4 py-2">
        <nav class="mb-3">
          <b-nav vertical>
            <b-nav-item :to="{ name: 'startseite' }"><i class="ifr if-home mr-2" />Startseite</b-nav-item>
            <Can I="access" a="projektmappe">
              <b-nav-item :to="{ name: 'projektmappe' }"><i class="ifr if-database mr-2" />Projektmappe</b-nav-item>
              <div class="pl-3 border-left">
                <Can I="access" a="projektmappe_anfragen">
                  <b-nav-item :to="{ name: 'anfrageroot' }"><i class="ifr if-inbox mr-2" />Anfragen</b-nav-item>
                  <div class="pl-3 border-left">
                    <b-nav-item :to="{ name: 'anfrage.liste.oeffentlich' }"><i class="ifr if-layer mr-2" />Öffentliche
                      Anfragen</b-nav-item>
                    <b-nav-item :to="{ name: 'anfrage.liste.nichtoeffentlich' }"><i
                        class="ifr if-layer mr-2" />Nichtöffentliche
                      Anfragen</b-nav-item>
                    <b-nav-item :to="{ name: 'anfrage.liste.neue' }"><i class="ifr if-inbox mr-2" />Neue
                      Anfragen</b-nav-item>
                    <b-nav-item :to="{ name: 'anfrage.liste.kein_angebot' }"><i class="fas fa-times mr-2" />Ohne
                      angebotsabgabe</b-nav-item>
                    <Can I="import" a="projektmappe_anfragen">
                      <b-nav-item :to="{ name: 'anfrage.import' }">Import</b-nav-item>
                    </Can>
                  </div>
                </Can>
              </div>
              <Can I="access" a="projektmappe_angebote">
                <div class="pl-3 border-left">
                  <b-nav-item :to="{ name: 'angebotroot' }"><i class="ifr if-inbox-special mr-2" />Angebote</b-nav-item>
                  <div class="pl-3 border-left">
                    <b-nav-item :to="{ name: 'angebot.liste.pruefung_erforderlich' }"><i
                        class="fas fa-user-check mr-2" />Prüfung
                      erforderlich</b-nav-item>
                    <Can I="import" a="projektmappe_angebote">
                      <b-nav-item :to="{ name: 'angebot.import' }">Import</b-nav-item>
                    </Can>
                  </div>
                </div>
              </Can>
              <Can I="access" a="projektmappe_auftraege">
                <div class="pl-3 border-left">
                  <b-nav-item :to="{ name: 'auftragroot' }"><i class="ifr if-delivery-slip mr-2" />Auftragsverwaltung</b-nav-item>
                  <div class="pl-3 border-left">
                    <b-nav-item :to="{ name: 'auftrag.liste.aktuell' }"><i class="ifr if-delivery-slip mr-2" />Aktuelle
                      Aufträge</b-nav-item>
                    <Can I="import" a="projektmappe_auftraege">
                      <b-nav-item :to="{ name: 'auftrag.import' }">Import</b-nav-item>
                    </Can>
                  </div>
                </div>
              </Can>
            </Can>
            <!-- <b-nav-item :to="{ name: 'filesystem-test' }"
              >Filsystem Test</b-nav-item
            > -->
            <Can I="access" a="settings">
              <b-nav-item :to="{ name: 'settings' }"><i class="fas fa-cog mr-2" />Einstellungen</b-nav-item>
              <div class="pl-3 border-left">
                <b-nav-item :to="{ name: 'mitarbeiterroot' }" v-if="$can('access', 'hr_mitarbeiter')"><i
                    class="ifr if-users mr-2" />Mitarbeiter</b-nav-item>
                <b-nav-item :to="{ name: 'abteilungroot' }" v-if="$can('access', 'hr_abteilungen')"><i
                    class="ifr if-customer-2 mr-2" />Abteilungen</b-nav-item>
                <b-nav-item href="/bx-casl/user" target="_blank" v-if="$can('manage_users', 'casl')"><i
                    class="fas fa-user mr-2" />Benutzer</b-nav-item>
                <b-nav-item href="/bx-casl/usergroup" target="_blank" v-if="$can('manage_usergroups', 'casl')"><i
                    class="fas fa-users mr-2" />Benutzergruppen</b-nav-item>
                <b-nav-item href="/bx-casl/module" target="_blank" v-if="$can('manage_abilities', 'casl')"><i
                    class="fas fa-key mr-2" />Berechtigungen</b-nav-item>
              </div>
            </Can>
          </b-nav>
        </nav>
      </div>
    </b-sidebar>
    <router-view v-if="abilitiesLoaded && stammdatenLoaded" />
  </div>
</template>

<script>
import store from "@st/index";
import { defineAbilities } from "@/config/ability";
import { SESSION } from "@/store/modules.type";
import {
  CONNECT_WEBSOCKET,
  DISCONNECT_WEBSOCKET,
} from "@/store/session/actions.type";
import { LOAD_STAMMDATEN } from "@/store/actions.type";

function handleRedirectToProjektmappe(to, next) {
  if (!store.getters["session/isLoggedIn"]) {
    next("/login");
  } else {
    if (to.name === "private") {
      next("/p/projektmappe/");
    } else {
      next();
    }
  }
}

export default {
  name: "PrivateLayout",
  beforeRouteEnter(to, from, next) {
    handleRedirectToProjektmappe(to, next)
  },
  beforeRouteUpdate(to, from, next) {
    handleRedirectToProjektmappe(to, next)
  },
  data() {
    return {
      abilitiesLoaded: false,
      stammdatenLoaded: false,
    };
  },
  async created() {
    var self = this;

    let a = this.$store.state.session.abilities;
    let abilities = defineAbilities(a);
    this.$ability.update(abilities);
    this.abilitiesLoaded = true;

    this.$store.dispatch(`${SESSION}/${CONNECT_WEBSOCKET}`);

    window.onbeforeunload = function () {
      self.$store.dispatch(`${SESSION}/${DISCONNECT_WEBSOCKET}`);
    };

    await store.dispatch(LOAD_STAMMDATEN);
    this.stammdatenLoaded = true;
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  padding-left: 0px;
}
</style>
