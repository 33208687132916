import user from "./user";
import projektmappe from "./projektmappe";
import stammdaten from "./stammdaten";
import hr from "./hr";
import dms from "./dms";
import aktivitaet from "./aktivitaet";


export default {
  user,
  projektmappe,
  stammdaten,
  dms,
  hr,
  aktivitaet
}
