import api from "@a/config";

const path ="v1/projektmappe/auftrag"

export default {
  get: (page, size, sort = null, desc = false, jahr = null, suche = null, bauleiter = null, ohneBauleiter = null, geschaeftsfeld = null, status = null) => {
    return api.get(path, { params: { page, size, sort, desc, jahr, suche, bauleiter, ohneBauleiter, geschaeftsfeld, status } })
  },
  getById: (id) => {
    return api.get(`${path}/${id}`)
  },
  getNaechsteNummer: (jahr) => {
    return api.get(`${path}/naechste_nummer`, { params: { jahr }})
  },
  create: (auftrag) => {
    return api.post(path, auftrag)
  },
  update: (id, auftrag) => {
    return api.put(`${path}/${id}`, auftrag)
  },
  delete: (id) => {
    return api.delete(`${path}/${id}`)
  },
  import: (formData) => {
    return api.post(`${path}/import`, formData)
  },
  importEingangsrechnung: (formData) => {
    return api.post(`${path}/eingangsrechnung/import`, formData)
  },
  importAusgangsrechnung: (formData) => {
    return api.post(`${path}/ausgangsrechnung/import`, formData)
  },
  importZeittabelle: (formData) => {
    return api.post(`${path}/zeittabelle/import`, formData)
  },
  importCashmanagement: (formData) => {
    return api.post(`${path}/cashmanagement/import`, formData)
  },
  importProjektUmlage: (id, formData) => {
    return api.post(`${path}/umlagen/import/${id}`, formData)
  },
  importBauteilkalkulation: (auftrag, bauteil, formData) => {
    return api.post(`${path}/bauteil/${auftrag}/${bauteil}/kalkulation/kosten`, formData)
  },
  getBauleiterZuordnung: () => {
    return api.get(`${path}/bauleiter/zuordnung`)
  },
  getRechnungen: (auftrag) => {
    return api.get(`${path}/${auftrag}/rechnungen`)
  },
  getZeiten: (auftrag) => {
    return api.get(`${path}/${auftrag}/zeiten`)
  },
  getUmlagen: (auftrag) => {
    return api.get(`${path}/${auftrag}/umlagen`)
  },
  getWidmungen: (auftrag) => {
    return api.get(`${path}/${auftrag}/widmungen`)
  },
  getPlanungen: (auftrag) => {
    return api.get(`${path}/${auftrag}/planungen`)
  },
  getHauptplanungen: (auftrag) => {
    return api.get(`${path}/${auftrag}/hauptplanungen`)
  },
  createHauptplanung: (auftrag, formData) => {
    return api.post(`${path}/${auftrag}/hauptplanungen`, formData)
  },
}
