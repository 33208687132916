import _ from "lodash";

export default {
  install: function(Vue) {
    Vue.mixin({
      methods: {
        $hasGroup(groupId) {
          if(!this.$store.state.session.user) return false;
          return _.some(this.$store.state.session.user.groups, { id: groupId });
        }
      },
    })
  }
}
