import { AbilityBuilder } from '@casl/ability'
import axios from "axios"

import _ from "lodash"

export function defineAbilities(a) {
  const { rules, can } = new AbilityBuilder()
  _.toPairs(a).forEach(([module, abilities]) => {
    _.toPairs(abilities).forEach(([ability, hasAbility]) => {
      if (hasAbility) {
        can([ability], module)
      }
    })
  })

  if (a.projektmappe_anfragen.access || a.projektmappe_angebote.access || a.projektmappe_auftraege.access) {
    can('access', 'projektmappe')
  }

  if (a.hr_mitarbeiter.access || a.hr_abteilungen.access || a.casl.manage_users || a.casl.manage_usergroups || a.casl.manage_abilities) {
    can('access', 'settings')
  }

  return rules;
}

export async function loadAbilities() {
  let response = await axios.get('/bx-casl-api/v1/user/ability', { withCredentials: true });
  return response.data
}
