import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  install: function(Vue) {
    Vue.mixin({
      methods: {
        $loader(el) {
          if(this.$loading) {
            let loader = this.$loading.show({
              // Optional parameters
              container: el
            });
            return loader
          }
        }
      },
    })
  }
}
