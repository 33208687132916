import { SET_DOKUMENTTYPEN } from "./mutations.type";

export default {
  namespaced: true,
  state: {
    dokument_typen: null,
  },
  mutations: {
    [SET_DOKUMENTTYPEN](state, val) {
      state.dokument_typen = val
    }
  },
  actions: {
  
  },
  modules: {
  },
  getters: {

  }
}
