import { SET_ANFRAGE_ARTEN, SET_ANFRAGE_ERGEBNISSE, SET_ANFRAGE_STATI, SET_ANFRAGE_ZUORDNUNG, SET_ANGEBOT_ARTEN, SET_ANGEBOT_STATI, SET_ANZAHL_ANGEBOTE_PRUEFUNG_ERFORDERLICH, SET_AUFTRAG_BAULEITER_ZUORDNUNG, SET_AUFTRAG_STATI, SET_GESCHAEFTSFELDER } from "./mutations.type";
import { LOAD_ANFRAGE_ZUORDNUNG, LOAD_ANZAHL_ANGEBOTE_PRUEFUNG_ERFORDERLICH, LOAD_AUFTRAG_BAULEITER_ZUORDNUNG } from './actions.type';
import api from '@a/index';
import { HR } from '@st/modules.type';
import _ from "lodash";
import anfragestatus from '@/constants/projektmappe/anfrage/status';


export default {
  namespaced: true,
  state: {
    anfrage_arten: null,
    anfrage_stati: null,
    auftrag_stati: null,
    anfrage_ergebnisse: null,
    angebot_arten: null,
    angebot_stati: null,
    geschaeftsfelder: null,
    anfrage_zuordnung: [],
    anzahl_angebote_pruefung_erforderlich: null,
    auftrag_bauleiter_zurodnung: []
  },
  mutations: {
    [SET_ANFRAGE_ARTEN](state, val) {
      state.anfrage_arten = val;
    },
    [SET_ANFRAGE_ERGEBNISSE](state, val) {
      state.anfrage_ergebnisse = val;
    },
    [SET_ANGEBOT_ARTEN](state, val) {
      state.angebot_arten = val;
    },
    [SET_ANGEBOT_STATI](state, val) {
      state.angebot_stati = val;
    },
    [SET_GESCHAEFTSFELDER](state, val) {
      state.geschaeftsfelder = val;
    },
    [SET_ANFRAGE_ZUORDNUNG](state, val) {
      state.anfrage_zuordnung = val;
    },
    [SET_ANFRAGE_STATI](state, val) {
      state.anfrage_stati = val;
    },
    [SET_AUFTRAG_STATI](state, val) {
      state.auftrag_stati = val;
    },
    [SET_ANZAHL_ANGEBOTE_PRUEFUNG_ERFORDERLICH](state, val) {
      state.anzahl_angebote_pruefung_erforderlich = val;
    },
    [SET_AUFTRAG_BAULEITER_ZUORDNUNG](state, val) {
      state.auftrag_bauleiter_zurodnung = val;
    }
  },
  actions: {
    async [LOAD_ANFRAGE_ZUORDNUNG]({ commit }) {
      let response = await api.v1.projektmappe.anfrage.getZuordnung();
      commit(SET_ANFRAGE_ZUORDNUNG, response.data)
    },
    async [LOAD_ANZAHL_ANGEBOTE_PRUEFUNG_ERFORDERLICH]({ commit }) {
      let response = await api.v1.projektmappe.angebot.getAnzahlPruefungErforderlich();
      commit(SET_ANZAHL_ANGEBOTE_PRUEFUNG_ERFORDERLICH, response.data.anzahl)
    },
    async [LOAD_AUFTRAG_BAULEITER_ZUORDNUNG]({ commit }) {
      let response = await api.v1.projektmappe.auftrag.getBauleiterZuordnung();
      commit(SET_AUFTRAG_BAULEITER_ZUORDNUNG, response.data)
    }
  },
  modules: {
  },
  getters: {
    bearbeiter_anfragen: (state, getters, rootState) => {
      return rootState[HR].mitarbeiter.filter(e => e.bearbeiter_anfragen)
    },
    bauleiter_auftraege: (state, getters, rootState) => {
      return rootState[HR].mitarbeiter.filter(e => e.bauleiter_auftrag)
    },
    laufende_anfragen: (state) => {
      return state.anfrage_zuordnung.filter(e => e.oeffentlich === null);
    },
    anzahl_laufende_anfragen: (state) => {
      return _.sumBy(state.anfrage_zuordnung.filter(e => e.status === anfragestatus.in_bearbeitung), e => e.anzahl_anfragen)
    },
    anzahl_neue_oeffentliche_anfragen: (state) => {
      return state.anfrage_zuordnung.find(e => e.oeffentlich)?.anzahl_anfragen || 0
    },
    anzahl_neue_nichtoeffentliche_anfragen: (state) => {
      return state.anfrage_zuordnung.find(e => e.oeffentlich === false)?.anzahl_anfragen || 0
    },
    anzahl_neue_anfragen: (state, getters) => {
      return getters.anzahl_neue_oeffentliche_anfragen + getters.anzahl_neue_nichtoeffentliche_anfragen
    },
    anzahl_aktuelle_auftraege: (state) => {
      return _.sumBy(state.auftrag_bauleiter_zurodnung, e => e.anzahl_auftraege)
    }
  }
}
