import axios from "axios";
import router from "@/router";

import store from "@st/index"
import { SESSION } from "@st/modules.type"
import { CLEAR_USER } from "@/store/session/actions.type";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true
})


api.interceptors.request.use(function (config) {
  // Do something before request is sent

  let websocket_client_id = store.state[SESSION].websocket_client_id;
  config.headers['x-websocket-client-id'] = websocket_client_id;
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // Benutzer ist niocht authentifiziert
  if (error.response?.status === 401) {
    store.dispatch(`${SESSION}/${CLEAR_USER}`)
    let url = `${window.location.pathname}${window.location.search}`.substring(process.env.VUE_APP_BASE_URL.length)
    let location = router.resolve("/" + url)
    if (location.resolved) {
      router.push({ name: 'login', query: { redir: location.resolved.fullPath } })
    } else {
      router.push({ name: 'login' })
    }
  }
  return Promise.reject(error);
});

export default api;
