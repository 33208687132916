export default [
  {
    path: 'settings',
    component: () => import("@l/settings/SettingsLayout.vue"),
    children: [
      {
        path: '',
        name: 'settings',
        component: () => import("@v/settings/SettingsStartseite.vue")
      },
      {
        path: 'mitarbeiter',
        name: 'mitarbeiterroot',
        component: () => import("@l/settings/MitarbeiterLayout.vue"),
        children: [
          {
            path: 'list',
            name: 'mitarbeiter.liste',
            component: () => import("@/views/settings/mitarbeiter/Mitarbeiterliste.vue")
          },
          {
            path: 'new',
            name: 'mitarbeiter.new',
            component: () => import("@/views/settings/mitarbeiter/Mitarbeiter.vue"),
            props: true
          },
          {
            path: ':id',
            name: 'mitarbeiter.id',
            component: () => import("@/views/settings/mitarbeiter/Mitarbeiter.vue"),
            props: true
          }
        ]
      },
      {
        path: 'abteilung',
        name: 'abteilungroot',
        component: () => import("@l/settings/AbteilungLayout.vue"),
        children: [
          {
            path: 'list',
            name: 'abteilung.liste',
            component: () => import("@/views/settings/abteilung/Abteilungliste.vue")
          },
          {
            path: 'new',
            name: 'abteilung.new',
            component: () => import("@/views/settings/abteilung/Abteilung.vue"),
            props: true
          },
          {
            path: ':id',
            name: 'abteilung.id',
            component: () => import("@/views/settings/abteilung/Abteilung.vue"),
            props: true
          }
        ]
      }
    ]
  }  
]