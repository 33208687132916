import api from "@a/config";

const path ="v1/projektmappe/angebot"

export default {
  get: (page, size, sort = null, desc = false, jahr = null, suche = null, art = null, status = null, geschaftsfeld = null, gegenpruefer = null, objekt = null, anschrift = null) => {
    return api.get(path, { params: { page, size, sort, desc, jahr, suche, art, status, geschaftsfeld, gegenpruefer, objekt, anschrift } })
  },
  getById: (id) => {
    return api.get(`${path}/${id}`)
  },
  getNaechsteNummer: (jahr) => {
    return api.get(`${path}/naechste_nummer`, { params: { jahr }})
  },
  create: (angebot) => {
    return api.post(path, angebot)
  },
  update: (id, updateAngebot) => {
    return api.put(`${path}/${id}`, updateAngebot)
  },
  delete: (id) => {
    return api.delete(`${path}/${id}`)
  },
  import: (formData) => {
    return api.post(`${path}/import`, formData)
  },
  getAuftrage: (id) => {
    return api.get(`${path}/${id}/auftrag`)
  },
  createAuftrag: (id) => {
    return api.post(`${path}/${id}/auftrag`)
  },
  getAnzahlPruefungErforderlich: () => {
    return api.get(`${path}/pruefung_erforderlich/anzahl`)
  }
}
