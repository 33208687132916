import api from "@a/config";
const path ="v1/dms"

export default {

  get: (entity, typ = null, page = null, size = null, sort = null, desc = null) => {
    return api.get(path, { params: { entity, typ, page, size, sort, desc }})
  },
  addDokument: (entity, typ, file) => {

    let formData = new FormData();
    formData.append("entity", entity);
    formData.append("file", file);
    formData.append("typ", typ);

    return api.post(path, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}