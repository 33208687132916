import api from "@a/index";
import router from "../../router";
import axios from "axios";
import { CLEAR_USER, LOAD_USER, LOGIN, LOGOUT, CONNECT_WEBSOCKET, DISCONNECT_WEBSOCKET, JOIN_ROOM, LEAVE_ROOM } from "./actions.type";
import { SET_ABILITIES, SET_CACHE_VERSION, SET_MITARBEITER, SET_USER, SET_USERGROUPS, SET_WEBSOCKET, SET_WEBSOCKET_CLIENT_ID } from "./mutations.type";

import websocket from "@ws/index";
import { GET_USER_ID } from './getters.type';

export default {
  namespaced: true,
  state: {
    user: null,
    mitarbeiter: null,
    abilities: null,
    usergroups: null,
    websocket: null,
    websocket_client_id: null,
    cache_version: null
  },
  mutations: {
    [SET_USER](state, val) {
      state.user = val
    },
    [SET_MITARBEITER](state, val) {
      state.mitarbeiter = val
    },
    [SET_ABILITIES](state, val) {
      state.abilities = val
    },
    [SET_USERGROUPS](state, val) {
      state.usergroups = val
    },
    [SET_WEBSOCKET](state, val) {
      state.websocket = val
    },
    [SET_CACHE_VERSION](state, val) {
      state.cache_version = val
    },
    [SET_WEBSOCKET_CLIENT_ID](state, val) {
      state.websocket_client_id = val
    }
  },
  actions: {
    async [LOAD_USER](context) {
      let response = await api.v1.user.getCurrent()
      context.commit(SET_USER, response.data.user)
      context.commit(SET_MITARBEITER, response.data.mitarbeiter || null)
      context.commit(SET_ABILITIES, response.data.abilities)
      context.commit(SET_USERGROUPS, response.data.usergroups)
      context.commit(SET_CACHE_VERSION, response.data.cache_version)

    },
    async [LOGIN](context, [username, password]) {
      let params = new URLSearchParams()
      params.append("username", username)
      params.append("password", password)

      let loginUrl = `/login/`;
      await axios.post(loginUrl, params);

      await context.dispatch(LOAD_USER);
    },
    async [LOGOUT](context) {
      await axios.post(`/logout/`, null, { withCredentials: true });
      context.dispatch(CLEAR_USER);
      router.push({ name: 'login' });
    },
    [CLEAR_USER](context) {
      context.commit(SET_USER, null)
      context.commit(SET_ABILITIES, null)
    },
    [CONNECT_WEBSOCKET]({ state, commit }) {
      let ws = websocket.connect(state.user?.id, "Frontend")
      commit(SET_WEBSOCKET, ws)
    },
    [DISCONNECT_WEBSOCKET]({ state }) {
      state.websocket?.close()
    },
    [JOIN_ROOM]({ state }, room) {
      state.websocket.join(room)
    },
    [LEAVE_ROOM]({ state }, room) {
      state.websocket.leave(room)
    }
  },
  modules: {
  },
  getters: {
    username: state => state.mitarbeiter ? `${state.mitarbeiter.vorname} ${state.mitarbeiter.name}` : state.user?.fullName,
    [GET_USER_ID]: state => state.user?.id,
    isLoggedIn: state => state.user !== null,
    isSupervisor: state => {
      if (!state.user) return false
      return state.user?.isBatixSupervisor
        || state.user?.isClientSupervior
        || state.user?.isSupervisor
        || state.user?.isSystemSupervisor
    }
  },

}

