import Vue from 'vue'
import Vuex from 'vuex'
import { LOAD_STAMMDATEN, SET_STAMMDATEN } from './actions.type'
import localstorage from "@ls/index"
import api from "@a/index"

import session from "./session"
import dms from "./dms"
import projektmappe from "./projektmappe"
import aktivitaet from './aktivitaet'
import viewport from './viewport'
import hr from './hr'

import { PROJEKTMAPPE, SESSION, DMS, AKTIVITAET, HR, VIEWPORT } from '@st/modules.type';
import { SET_DOKUMENTTYPEN } from '@st/dms/mutations.type';
import { SET_ANFRAGE_ARTEN, SET_ANFRAGE_ERGEBNISSE, SET_ANFRAGE_STATI, SET_ANGEBOT_ARTEN, SET_ANGEBOT_STATI, SET_AUFTRAG_STATI, SET_GESCHAEFTSFELDER } from '@st/projektmappe/mutations.type';
import { SET_AKTIVITAETARTEN } from '@st/aktivitaet/mutations.type'
import { SET_MITARBEITER, SET_ABTEILUNGEN } from './hr/mutations.type';



Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  actions: {
    async [LOAD_STAMMDATEN]({ state, dispatch}) {

      if(localstorage.cache.isReloadRequired(state[SESSION].cache_version)) {
        console.log("[Stammdaten] Reload required")
        let response = await api.v1.stammdaten.get();
        
        dispatch(SET_STAMMDATEN, response.data)
        
        localstorage.cache.setStammdaten(response.data);
        localstorage.cache.setCacheVersion(state[SESSION].cache_version);
      } else {
        let stammdaten = localstorage.cache.getStammdaten()
        dispatch(SET_STAMMDATEN, stammdaten)
      }
    },
    [SET_STAMMDATEN](context, stammdaten) {
      let { commit } = context;
      commit(`${DMS}/${SET_DOKUMENTTYPEN}`, stammdaten.dms.dokument_typen)
      commit(`${AKTIVITAET}/${SET_AKTIVITAETARTEN}`, stammdaten.aktivitaet.aktivitaet_arten)
      commit(`${PROJEKTMAPPE}/${SET_ANFRAGE_ARTEN}`, stammdaten.projektmappe.anfrage_arten)
      commit(`${PROJEKTMAPPE}/${SET_ANFRAGE_STATI}`, stammdaten.projektmappe.anfrage_stati)
      commit(`${PROJEKTMAPPE}/${SET_ANFRAGE_ERGEBNISSE}`, stammdaten.projektmappe.anfrage_ergebnisse)
      commit(`${PROJEKTMAPPE}/${SET_ANGEBOT_ARTEN}`, stammdaten.projektmappe.angebot_arten)
      commit(`${PROJEKTMAPPE}/${SET_ANGEBOT_STATI}`, stammdaten.projektmappe.angebot_stati)
      commit(`${PROJEKTMAPPE}/${SET_AUFTRAG_STATI}`, stammdaten.projektmappe.auftrag_stati)
      commit(`${PROJEKTMAPPE}/${SET_GESCHAEFTSFELDER}`, stammdaten.projektmappe.geschaeftsfelder)
      commit(`${HR}/${SET_MITARBEITER}`, stammdaten.hr.mitarbeiter)
      commit(`${HR}/${SET_ABTEILUNGEN}`, stammdaten.hr.abteilungen)
    }

  },
  modules: {
    [SESSION]: session,
    [DMS]: dms,
    [PROJEKTMAPPE]: projektmappe,
    [AKTIVITAET]: aktivitaet,
    [HR]: hr,
    [VIEWPORT]: viewport
  }
})
