<template>
  <div id="app" class="pb-5 position-relative">
    <viewport-control />
    <router-view
      v-if="$store.state.session.user !== null || $route.name === 'login'"
    />
    <file-modal ref="fileModal"></file-modal>
    <new-version-modal ref="newVersionModal"></new-version-modal>
    <div
      class="text-right font-size-xs position-absolute text-steel d-print-none"
      style="right: 3px; bottom: 0px"
    >
      v {{ version }}
    </div>
  </div>
</template>

<script>
import EventBus, { SHOW_FILE, SHOW_NEW_APP_VERSION } from "./event-bus.js";
import FileModal from "@c/FileModal.vue";
import NewVersionModal from "@c/NewVersionModal.vue";
import ViewportControl from "@c/ViewportControl.vue";

const version = process.env.VUE_APP_VERSION;

export default {
  name: "App",
  components: {
    FileModal,
    NewVersionModal,
    ViewportControl,
  },
  data() {
    return {
      sm: false,
      md: false,
      lg: false,
      xl: false,
      xxl: false,
      uw: false,
    };
  },
  computed: {
    version() {
      return version;
    },
  },
  mounted() {
    var self = this;
    EventBus.$on(SHOW_FILE, function (file) {
      self.$refs.fileModal.show(file);
    });
    EventBus.$on(SHOW_NEW_APP_VERSION, function () {
      self.$refs.newVersionModal.show();
    });
  },
  methods: {
    handleSmVisible(visible) {
      this.sm = visible;
    },
    handleMdVisible(visible) {
      this.md = visible;
    },
    handleLgVisible(visible) {
      this.lg = visible;
    },
  },
};
</script>

<style lang="scss">
@import "./styles/scss/app.scss";
</style>
