
import { DateTime } from "luxon";

export function dateFromMillis(value) {
  if (!value ||isNaN(value) || !isFinite(value)) return "";
    return DateTime.fromMillis(value).toFormat("dd.MM.yyyy");
}

export function millisFromDate(value) {
  if (!value) return null;
  return value.toMillis();
}

export function dateTimeFromMillis(value) {
  if (!value ||isNaN(value) || !isFinite(value)) return "";
    return DateTime.fromMillis(value).toFormat("dd.MM.yyyy HH:mm");
}

export function timeFromMillis(value) {
  if (!value ||isNaN(value) || !isFinite(value)) return "";
    return DateTime.fromMillis(value).toFormat("HH:mm");
}

export function weekDayFromMillis(value) {
  if (!value ||isNaN(value) || !isFinite(value)) return "";
  return DateTime.fromMillis(value).setLocale('de').toFormat("EEE");
}

export function setup(instance) {

  instance.filter("dateFromMillis", (value) => {
    return dateFromMillis(value)
  });

  instance.filter("dateTimeFromMillis", (value) => {
    return dateTimeFromMillis(value)
  })

  instance.filter("timeFromMillis", (value) => {
    return timeFromMillis(value)
  })

  instance.filter("weekday", (value) => {
    if(value === null || value === undefined ||!value) return ""
    if(typeof(value) === Number) {
      return weekDayFromMillis(value)
    } else if(typeof(value) === "object") {
      return value.setLocale('de').toFormat("EEE")
    } else {
      return ""
    }
  })
}
