export default {
  install: function (Vue, options) {
    Vue.mixin({
      computed: {
        $env () {
          return {
            serverUrl: process.env.VUE_APP_SERVER_URL,
            isMobile: options ? options.isMobile : undefined,
          }
        }
      }
    })
  }
}
