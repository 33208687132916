import { dateFromMillis } from "@/filter/date";
import { priceEuroOrBlank, thousendSeparator } from "@/filter/numeral";
import formatter from "@u/formatter"

export var status = {
  key: "status",
  sortable: true,
  sortKey: "auftrag_status.name",
  label: "Status",
  formatter: formatter.standardKategorie
}

export var laufendeNummer = {
  key: "nummer",
  sortable: true,
  sortKey: "auftrag.nummer",
  label: "LK-Nr.",
  thClass: "text-center",
  tdClass: "text-center"
}

export var auftragsnummer = {
  key: "auftragsnummer",
  sortable: true,
  sortKey: "auftrag.auftragsnummer",
  label: "LK-Nr.",
  thClass: "text-center",
  tdClass: "text-center"
}

export var auftragsdatum = {
  key: "auftragsdatum",
  sortable: true,
  sortKey: "auftrag.auftragsdatum",
  label: "Auftragsdatum",
  tdClass: "text-center",
  thClass: "text-center",
  formatter: dateFromMillis
}

export var fertigungVon = {
  key: "fertigung_von",
  sortable: true,
  sortKey: "auftrag.fertigung_von",
  label: "NU/MMS Fertigung",
  thClass: "text-center",
  tdClass: "text-center"
}

export var fertigungStunden = {
  key: "fertigung_stunden",
  sortable: true,
  sortKey: "auftrag.fertigung_stunden",
  label: "Stunden Fertigung",
  thClass: "text-center",
  tdClass: "text-right",
  formatter: (value) => {
    return thousendSeparator(value)
  }
}

export var montageVon = {
  key: "montage_von",
  sortable: true,
  sortKey: "auftrag.montage_von",
  label: "NU/MMS Montage",
  thClass: "text-center",
  tdClass: "text-center"
}

export var montageStunden = {
  key: "montage_stunden",
  sortable: true,
  sortKey: "auftrag.montage_stunden",
  label: "Stunden Montage",
  tdClass: "text-right",
  formatter: (value) => {
    return thousendSeparator(value)
  }
}

export var bauvorhaben = {
  key: "bauvorhaben",
  sortable: true,
  sortKey: "auftrag.bauvorhaben",
  label: "Bauvorhaben"
}

export var bauleiter = {
  key: "bauleiter",
  sortable: true,
  sortKey: "auftrag_bauleiter.vorname",
  label: "Bauleiter",
  formatter: formatter.mitarbeiterName
}

export var werkplan = {
  key: "werkplan",
  sortable: true,
  sortKey: "auftrag.werkplan",
  label: "Werkplan"
}

export var kalkulatoischeSummeWerkplan = {
  key: "kalulatorische_summe_werkplan",
  sortable: true,
  sortKey: "auftrag.kalulatorische_summe_werkplan",
  label: "Kalk. Summe Werkplan",
  tdClass: "text-right avoid-line-break",
  formatter: (value) => {
    return priceEuroOrBlank(value)
  }
}

export var auftragssumme = {
  key: "auftragssumme",
  sortable: true,
  sortKey: "auftrag.auftragssumme",
  label: "Netto",
  tdClass: "text-right avoid-line-break",
  formatter: (value) => {
    return priceEuroOrBlank(value)
  }
}

export var beauftragteNachtraege = {
  key: "beauftragte_nachtraege",
  sortable: true,
  sortKey: "auftrag.beauftragte_nachtraege",
  label: "Nachträge beauftragt",
  tdClass: "text-right avoid-line-break",
  formatter: (value) => {
    return priceEuroOrBlank(value)
  }
}

export var geschaeftsfeld = {
  key: "geschaeftsfeld",
  sortable: true,
  sortKey: "auftrag_geschaeftsfeld.sort",
  label: "Geschäftsfeld",
  formatter: val => {
    return val ? `${val.sort} - ${val.name}` : ""
  }
}

export var bemerkungen = {
  key: "bemerkung",
  label: "Bemerkung",
}

export var ausfuehrungsbeginn = {
  key: "ausfuehrungsbeginn",
  label: "Ausführungsbeginn",
}

export var vertragsende = {
  key: "vertragsende",
  label: "Vertragsende",
}

export var vertragsstrafe = {
  key: "vertragsstrafe",
  label: "Vertragsstrafe",
}

export var auftragsbeginn = {
  key: "auftragsbeginn",
  label: "Auftragsbeginn",
  sortable: true,
  sortKey: "auftrag.auftragsbeginn",
  tdClass: "text-center",
  thClass: "text-center",
  formatter: dateFromMillis
}

export var fertigstellung = {
  key: "fertigstellung",
  label: "Fertigstellung",
  sortable: true,
  sortKey: "auftrag.fertigstellung",
  tdClass: "text-center",
  thClass: "text-center",
  formatter: dateFromMillis
}


export default [
  auftragsnummer,
  auftragsdatum,
  fertigungVon,
  fertigungStunden,
  montageVon,
  montageStunden,
  bauvorhaben,
  status,
  bauleiter,
  geschaeftsfeld,
  auftragssumme,
  beauftragteNachtraege,
  bemerkungen,
  werkplan,
  kalkulatoischeSummeWerkplan,
  ausfuehrungsbeginn,
  vertragsende,
  vertragsstrafe,
  auftragsbeginn,
  fertigstellung
];