import { SET_MITARBEITER, SET_ABTEILUNGEN } from './mutations.type';

export default {

  namespaced: true,
  state: {
    mitarbeiter: [],
    abteilungen: []
  },
  mutations: {
    [SET_MITARBEITER](state, val) {
      state.mitarbeiter = val;
    },
    [SET_ABTEILUNGEN](state, val) {
      state.abteilungen = val;
    }
  },
  actions: {
  
  },
  modules: {
  },
  getters: {

  }
}
