import { LISTE_PER_PAGE, ADDITIONAL_FIELDS, SELECTED_YEAR } from "./key.type"
import config from "@ls/config";

function getAdditionalFieldsKey(userId, routeName) {
  return `${ADDITIONAL_FIELDS}_${userId}_${routeName}`
}

function getSelectedYearKey(userId, routeName) {
  return `${SELECTED_YEAR}_${userId}_${routeName}`
}

export default {
  setPerPage: (suffix, value) => {
    localStorage.setItem(config.cachePrefix + LISTE_PER_PAGE + suffix, value)
  },
  getPerPage: (suffix) => {
    let val = localStorage.getItem(config.cachePrefix + LISTE_PER_PAGE + suffix)
    if (val) return parseInt(val)
    return null
  },
  setAdditionalFields(userId, routeName, value) {
    localStorage.setItem(getAdditionalFieldsKey(userId, routeName), JSON.stringify(value))
  },
  getAdditionalFieldsKey(userId, routeName) {
    let val = localStorage.getItem(getAdditionalFieldsKey(userId, routeName))
    if (val) return JSON.parse(val)
    return []
  },
  setSelectedYear: function (userId, routeName, value) {
    localStorage.setItem(getSelectedYearKey(userId, routeName), JSON.stringify(value))
  },
  getSelectedYear(userId, routeName) {
    let val = localStorage.getItem(getSelectedYearKey(userId, routeName))
    if (val) return parseInt(val)
    return null
  },
}
