import { setup as setupNumeralFilters } from "./numeral";
import { setup as setupDateFilters } from "./date";
import { setup as setupMitarbeiterFilters } from "./mitarbeiter";

export function setupFilters (instance) {

  setupNumeralFilters(instance);

  setupDateFilters(instance);

  setupMitarbeiterFilters(instance);
}
