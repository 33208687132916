import router from "@/router"
import { MODULE_OBJECT_KEY } from './constants';

export default {
  install: function (Vue) {

    if (Vue.__moduleabilities_installed) {
      return
    }
    Vue.__moduleabilities_installed = true

    Vue.mixin({
      beforeCreate () {
        Object.defineProperty(this, MODULE_OBJECT_KEY, {});
      },
      created () {
        if (this[MODULE_OBJECT_KEY]) {
          let module = this[MODULE_OBJECT_KEY]
          if (this.$cannot('access', module)) {
            router.push({ name: "forbidden" })
          }
        }
      },
    })
  }
}
